export default [
  {
    text: 'campaign.form.name',
    value: 'name',
  },
  {
    text: 'campaign.form.status',
    value: 'status',
    type: 'slot',
  },
  {
    text: 'campaign.form.error',
    value: 'error',
  },
  {
    text: 'campaign.form.clickAction',
    value: 'clickAction',
    type: 'slot',
  },
  {
    text: 'campaign.form.scheduledAt',
    value: 'scheduledAt',
    type: 'date',
    format: 'DD.MM.YYYY HH:mm',
  },
];
