
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import CampaignFilter from '@/components/campaign/CampaignFilter.vue';
import headers from './headers';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import { Campaign } from '@/interfaces/models/Campaign';
import { Venue } from '@/interfaces/models/Venue';
import { User } from '@/interfaces/models/User';
import { CustomerGroup as CustomerGroupEnum } from '@/enums/CustomerGroup';
import { Permission } from '@/enums/Permission';
import { CampaignStatus } from '@/enums/CampaignStatus';

@Component({
  components: { VTable, CampaignFilter },
})
export default class CampaignTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Campaign[];
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Array, required: true }) public customerGroups!: CustomerGroupEnum[];
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop({ type: Object }) public user!: User;
  @Prop({ type: Object }) public venue!: Venue;
  @Prop({ type: String, required: false }) public customerGroup!: string;
  @Prop({ type: Boolean, required: true }) public canDelete!: boolean;

  public headers: ColumnHeader[] = headers;

  public statusColor(status: CampaignStatus) {
    return status === CampaignStatus.PENDING
      ? 'yellow lighten-3'
      : status === CampaignStatus.FAILED
      ? 'red lighten-3'
      : 'green lighten-3';
  }

  public canEdit(item: Campaign) {
    if (this.$isAdmin()) {
      return true;
    }

    if (!this.$can(Permission.CAMPAIGN_UPDATE)) {
      return false;
    }

    if (item.customerGroup && item.venues.length === 0 && !this.customerGroup) {
      return false;
    }

    if (!item.venues?.length) {
      return true;
    }

    for (const venue of item.venues) {
      if (!this.venues.find((v: Venue) => v._id === venue)) {
        return false;
      }
    }

    return true;
  }
}
