
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VWrapper from '@/components/shared/VWrapper.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import CampaignTable from '@/components/campaign/CampaignTable.vue';
import { namespace } from 'vuex-class';
import Filter from '@/interfaces/api/Filter';
import CustomerGroup from '@/mixins/CustomerGroup';
import { Campaign as CampaignModel } from '@/interfaces/models/Campaign';
import { Venue } from '@/interfaces/models/Venue';
import { User } from '@/interfaces/models/User';

const campaign = namespace('campaign');
const app = namespace('app');
const auth = namespace('auth');
const venue = namespace('venue');

@Component({
  components: { VWrapper, VEmptyState, CampaignTable },
})
export default class Campaign extends mixins(CustomerGroup) {
  @app.State('venues') public venues!: Venue[];
  @auth.State('user') public user!: User;
  @venue.State('active') public venue!: Venue;

  @campaign.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @campaign.Action('fetch') public getItems!: any;
  @campaign.Action('delete') public deleteCampaign!: any;

  @campaign.State((state) => state.pagination.total) public total!: number;
  @campaign.State('filter') public filter!: Filter;
  @campaign.State('items') public items!: [];

  public async mounted() {
    this.$startLoading('campaigns');
    await this.setFilter({});
    this.$stopLoading('campaigns');
  }

  public async onFilter(val: object) {
    if (!val) {
      return;
    }

    this.$startLoading('campaigns');
    this.setFilter(val);
    await this.getItems({ page: 1 });
    this.$stopLoading('campaigns');
  }

  public async remove(campaign: CampaignModel) {
    this.$startLoading('campaigns');
    await this.deleteCampaign({ id: campaign._id });
    this.$stopLoading('campaigns');
  }

  public edit(item: CampaignModel) {
    this.$router.push({
      name: 'campaign.edit',
      params: { id: item._id },
      query: { ...this.$route.query },
    });
  }
}
